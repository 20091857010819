<template>
  <b-row class="justify-content-between justify-content-xl-start align-items-center mb-3 mb-xl-5">
    <b-col cols="auto">
      <b-dropdown
        id="filter-dropdown"
        :text="$t('button.filter')"
        ref="dropdown"
        variant="primary"
        style="width:96px;"
        @show="resetFilterOptions"
      >
        <b-dropdown-form class="pb-0" v-if="filterDisplayConfigs.rating">
          <b-form-group class="mb-0">
            <div class="d-flex mb-2 justify-content-between">
              <span>{{ $t('filter.minimum_number_of_stars') }}</span>
              <span>{{ filterOptions.rating }}</span>
            </div>
            <b-form-input
              v-model="filterOptions.rating"
              type="range"
              min="1"
              max="5"
              step="0.5"
            ></b-form-input>
          </b-form-group>
        </b-dropdown-form>
        <b-dropdown-form v-if="filterDisplayConfigs.repeatRate">
          <b-form-group class="mb-0">
            <div class="d-flex mb-2 justify-content-between">
              <span
                >{{ $t('filter.repeat_rate') }}
                <b-img
                  id="information-icon"
                  src="../assets/images/information-icon-gray.svg"
                  class="mb-1"
                  style="cursor: pointer;"
                  @click="isShowTooltip = !isShowTooltip"
                ></b-img
              ></span>
              <b-tooltip :show="isShowTooltip" target="information-icon" placement="bottom">
                {{ $t('form_label.repeat_rate') }}
              </b-tooltip>
              <span>{{ filterOptions.repeatRate }} %</span>
            </div>
            <b-form-input
              v-model="filterOptions.repeatRate"
              type="range"
              min="0"
              max="100"
              step="10"
            ></b-form-input>
          </b-form-group>
        </b-dropdown-form>
        <b-dropdown-form v-if="filterDisplayConfigs.totalReviews">
          <b-form-group class="mb-0">
            <div class="d-flex mb-2 justify-content-between">
              <span>{{ $t('filter.user_rating') }}</span>
              <span>{{ filterOptions.totalReviews }} {{ $t('alias.reviews') }}</span>
            </div>
            <b-form-input
              v-model="filterOptions.totalReviews"
              type="range"
              min="0"
              max="499"
              step="1"
            ></b-form-input>
          </b-form-group>
        </b-dropdown-form>
        <b-dropdown-form v-if="filterDisplayConfigs.completeHrs">
          <b-form-group class="mb-0">
            <div class="d-flex mb-2 justify-content-between">
              <span>{{ $t('filter.total_work_completed') }}</span>
              <span>{{ filterOptions.completeHrs }} {{ $t('alias.times') }}</span>
            </div>
            <b-form-input
              v-model="filterOptions.completeHrs"
              type="range"
              min="0"
              max="999"
              step="1"
            ></b-form-input>
          </b-form-group>
        </b-dropdown-form>
        <b-dropdown-form v-if="filterDisplayConfigs.quality">
          <b-form-group class="mb-0">
            <div class="d-flex mb-2 justify-content-between">
              <span>{{ $t('filter.maid_quality') }}</span>
            </div>
            <b-form-group>
              <b-form-checkbox-group v-model="filterOptions.quality">
                <b-form-checkbox value="SUPER_MAID">{{
                  $t('quality_levels.super')
                }}</b-form-checkbox>
                <b-form-checkbox value="TOP_MAID">{{ $t('quality_levels.top') }}</b-form-checkbox>
                <b-form-checkbox value="QUALITY_MAID">{{
                  $t('quality_levels.quality')
                }}</b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>
          </b-form-group>
        </b-dropdown-form>
        <b-dropdown-form v-if="filterDisplayConfigs.bringEquipment" class="mt-2">
          <b-form-checkbox
            id="bring_equipment"
            v-model="filterOptions.bring_equipment"
            name="bring_equipment"
            :disabled="disabledOptions.bring_equipment"
            :value="true"
            :unchecked-value="false"
          >
            {{ $t('filter.bring_equipment') }}
          </b-form-checkbox>
        </b-dropdown-form>
        <b-dropdown-form v-if="filterDisplayConfigs.canIron">
          <b-form-checkbox
            id="can_iron"
            v-model="filterOptions.canIron"
            name="can_iron"
            :disabled="disabledOptions.canIron"
            :value="true"
            :unchecked-value="false"
          >
          {{ $t('filter.can_iron') }}
          </b-form-checkbox>
        </b-dropdown-form>
        <b-dropdown-form v-if="filterDisplayConfigs.canSpeakEnglish">
          <b-form-checkbox
            id="can_speak_english"
            v-model="filterOptions.canSpeakEnglish"
            name="can_speak_english"
            :value="true"
            :unchecked-value="false"
          >
          {{ $t('filter.can_speak_english') }}
          </b-form-checkbox>
        </b-dropdown-form>
        <b-dropdown-form v-if="filterDisplayConfigs.canSpeakJapanese">
          <b-form-checkbox
            id="can_speak_japanese"
            v-model="filterOptions.canSpeakJapanese"
            name="can_speak_japanese"
            :value="true"
            :unchecked-value="false"
          >
          {{ $t('filter.can_speak_japanese') }}
          </b-form-checkbox>
        </b-dropdown-form>
        <b-dropdown-form class="py-0" v-if="isLoggedIn && filterDisplayConfigs.favoriteMaids">
          <b-form-group class="mb-0">
            <div class="d-flex justify-content-between mb-1">
              <span>{{ $t('filter.my_favorite_maid') }}</span>
              <b-form-checkbox switch v-model="filterOptions.favoriteMaids"></b-form-checkbox>
            </div>
          </b-form-group>
        </b-dropdown-form>
        <b-dropdown-form class="py-0" v-if="isLoggedIn && filterDisplayConfigs.ratedhighly">
          <b-form-group class="mb-0">
            <div class="d-flex justify-content-between mb-1">
              <span>{{ $t('filter.maid_rated_highly_by_my_friends') }}</span>
              <b-form-checkbox switch v-model="filterOptions.ratedhighly"></b-form-checkbox>
            </div>
          </b-form-group>
        </b-dropdown-form>
        <b-dropdown-form class="py-0" v-if="isLoggedIn && filterDisplayConfigs.previoslyUsed">
          <b-form-group class="mb-0">
            <div class="d-flex">
              <b-form-checkbox v-model="filterOptions.previoslyUsed"></b-form-checkbox>
              <span>{{ $t('filter.maids_previously_used_by_myself') }}</span>
            </div>
          </b-form-group>
        </b-dropdown-form>
        <b-dropdown-form class="py-0">
          <b-form-group class="mb-0">
            <div class="d-flex justify-content-between py-2">
              <b-button block variant="primary" @click="doFilter">{{
                $t('button.filter')
              }}</b-button>
            </div>
          </b-form-group>
        </b-dropdown-form>
      </b-dropdown>
    </b-col>
    <b-col col xl="auto">{{ total }} {{ $t('alias.maids') }}</b-col>
    <b-col cols="auto">
      <b-dropdown
        id="sortby-dropdown"
        :text="$t('button.sort')"
        right
        variant="primary"
        style="width:96px;"
      >
        <b-dropdown-item
          v-for="option in sortOptions"
          :active="filterOptions.sortBy === option.value"
          @click="onSelectSortBy(option.value)"
          :key="option.value"
          >{{ $t(`sort_by.${option.text}`) }}</b-dropdown-item
        >
      </b-dropdown>
    </b-col>
  </b-row>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator'
import { createNamespacedHelpers } from 'vuex'
const MaidStore = createNamespacedHelpers('maid')
const AuthStore = createNamespacedHelpers('auth')
@Component({
  props: {
    options: {
      type: Object,
      default: function() {
        return {
          rating: 0,
          repeatRate: 0,
          totalReviews: 0,
          completeHrs: 1,
          quality: [],
          sortBy: 'rating',
          favoriteMaids: false,
          ratedhighly: false,
          previoslyUsed: false,
          bring_equipment: false,
          canIron: false,
          canSpeakEnglish: false,
          canSpeakJapanese: false,
        }
      }
    },
    total: {
      default: 0,
      type: Number
    }
  },
  computed: {
    ...MaidStore.mapGetters(['totalMaids']),
    ...AuthStore.mapState(['isLoggedIn'])
  }
})
export default class FilterMaid extends Vue {

  filterDisplayConfigs = {
    rating: false,
    repeatRate: false,
    totalReviews: false,
    completeHrs: false,
    quality: false,
    bringEquipment: true,
    canIron: true,
    canSpeakEnglish: true,
    canSpeakJapanese: true,
    favoriteMaids: false,
    ratedhighly: false,
    previoslyUsed: true,
  }

  isShowTooltip = false

  filterCheckboxOptions = [
    { text: 'Super', value: 'SUPER_MAID' },
    { text: 'Top', value: 'TOP_MAID' },
    { text: 'Quality', value: 'QUALITY_MAID' }
  ]

  sortOptions = [
    { text: 'rating', value: 'rating' },
    { text: 'repeat_rate', value: 'repeat_rate' },
    { text: 'total_work_completed', value: 'total_hours' }
  ]

  filterOptions = { ...this.options }

  sortBy = 'rating'

  disabledOptions = {
      canIron: this.$route.query.services && (this.$route.query.services === 'DRAPERY' || this.$route.query.services === 'CLEANING_DRAPERY'),
      bring_equipment: this.$route.query.bring_equipment == 1
  }

  doFilter() {
    this.$refs.dropdown.hide(true)
    console.log('filterOptions', this.filterOptions)
    this.$emit('onOptionsChange', this.filterOptions)
  }
  onSelectSortBy(option) {
    this.filterOptions.sortBy = option
    this.$emit('onOptionsChange', this.filterOptions)
  }

  resetFilterOptions() {
    this.filterOptions = { ...this.options }
  }
}
</script>
